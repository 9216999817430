

















import { Component, Vue } from 'vue-property-decorator'

import EventsForm from '@/partials/forms/Events/EventsForm.vue'

@Component({
  components: {
    EventsForm
  }
})
export default class EventCreate extends Vue {

}
